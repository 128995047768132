import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { navigate } from "@reach/router"

// Helpers
import getPosition from "~helpers/positions"
import { getOuterWidth } from "~helpers/dimensions"

// Styles
import Styles from "./Supfooter.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import Icon from "~icons/Icon"

// Libs
import slugify from "slugify"

const slugifyConfig = {
    replacement: "-",
    remove: undefined,
    lower: true,
    strict: true,
}

class Supfooter extends Component {
    constructor(props) {
        super(props)

        this.tickerItems = []
        this.tickerItemsData = []
        this.totalWidth = 0

        // Refs
        this.ticker = React.createRef()
    }

    state = {
        isTickerReady: false,
        isPaused: false,
        speed: 1.5,
    }

    setDataToRefs() {
        this.tickerItemsData = this.tickerItems.map((ref, index) => {
            if (ref) {
                this.totalWidth += getOuterWidth(ref)

                return {
                    ref,
                    currentOffset: 0,
                    offsetToGo: getPosition(ref).left + getOuterWidth(ref),
                }
            }
        })
    }

    renderLinksWithData(data) {
        let Items = data.map((item, index) => {
            return (
                <div
                    className={Styles.Supfooter__ticker__item}
                    key={`ticker-item-${index}`}
                    ref={(ref) => {
                        this.tickerItems[index] = ref
                    }}
                >
                    <Link
                        className={`${Styles.Supfooter__ticker__item__link} teasing-3`}
                        to={`/sites/${slugify(item.node.name, slugifyConfig)}`}
                    >
                        <span
                            className={`result-number result-number--small`}
                        >{`${item.node.id
                            .toString()
                            .padStart(2, "0")}_ `}</span>
                        {item.node.name}
                    </Link>
                </div>
            )
        })

        return Items
    }

    update() {
        this.raf = requestAnimationFrame(this.update.bind(this))

        if (this.state.isPaused) {
            return
        }

        this.tickerItemsData.forEach((item, index) => {
            if (!item) {
                return
            }

            item.currentOffset += this.state.speed

            if (item.currentOffset >= item.offsetToGo) {
                item.currentOffset = -(this.totalWidth - item.offsetToGo)
            }

            item.ref.style.transform = `translateX(${-item.currentOffset}px)`
        })
    }

    onMouseEnter() {
        this.setState({
            isPaused: true,
            speed: 0,
        })
    }

    onMouseLeave() {
        this.setState({
            isPaused: false,
            speed: 2,
        })
    }

    componentDidMount() {
        // Waiting for the refs to be added to the DOM
        const checker = setInterval(() => {
            // Are they really there ?
            if (this.tickerItems.length > 0) {
                // No, but like really ?
                if (typeof this.tickerItems[0] !== "undefined") {
                    // Clearing this watchdog
                    clearInterval(checker)

                    // Waiting for the ref to be fully loaded
                    setTimeout(() => {
                        // And setting the props.
                        this.setDataToRefs()
                        this.update()
                    }, 100)
                }
            }
        }, 10)
    }

    componentWillUnmount() {
        this.undraw()
    }

    undraw() {
        cancelAnimationFrame(this.raf)
        this.raf = null
    }

    render() {
        return (
            <div className={Styles.Supfooter}>
                <Row>
                    <Cell start="0" end="12">
                        <div className={Styles.Supfooter__inner}>
                            <div className={Styles.Supfooter__left}>
                                <div
                                    className={Styles.Supfooter__ticker}
                                    onMouseEnter={this.onMouseEnter.bind(this)}
                                    onMouseLeave={this.onMouseLeave.bind(this)}
                                >
                                    <StaticQuery
                                        query={query}
                                        render={(data) =>
                                            this.renderLinksWithData(
                                                data.allSitesJson.edges
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className={Styles.Supfooter__right}>
                                <Link
                                    to={"/sites"}
                                    className={`btn btn--primary`}
                                >
                                    <span className={`btn__text`}>
                                        Voir les 12 profils
                                    </span>
                                    <div className={`btn--primary__right`}>
                                        <Icon
                                            name={`big-arrow-right`}
                                            width={48}
                                            height={48}
                                            fill="#000"
                                        />
                                        <Icon
                                            name={`big-arrow-right`}
                                            width={48}
                                            height={48}
                                            fill="#000"
                                        />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </Cell>
                </Row>
            </div>
        )
    }
}

export const query = graphql`
    {
        allSitesJson {
            edges {
                node {
                    name
                    id
                }
            }
        }
    }
`

export default Supfooter
